.timeline-sec {
    position: relative;
    z-index: 0;
    z-index: 0;
    overflow: hidden;
    padding: 75px 0 0 0;
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 0;
        z-index: 1;
        width: 50%;
        height: 100%;
        background: -moz-linear-gradient(left, rgba(128, 128, 128, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-gradient(
            left top,
            right top,
            color-stop(0%, rgba(128, 128, 128, 0.7)),
            color-stop(100%, rgba(0, 0, 0, 0))
        );
        background: -webkit-linear-gradient(left, rgba(128, 128, 128, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(left, rgba(128, 128, 128, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
        background: -ms-linear-gradient(left, rgba(128, 128, 128, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to right, rgba(128, 128, 128, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#808080', endColorstr='#000000', GradientType=1);
    }
}

.profile-sec-seo {
    background-color: #f9f9f9;
    padding: 0;

    .practician-name {
        .media-img {
            background-color: #fff;
            height: 220px;
            width: 220px;
            border: 1px solid #e9e9e9;
            border-radius: 5px;
            padding: 5px;
            position: relative;
            top: -60px;
            box-shadow: 0px 3px 10px #0000000d;

            &::after {
                content: "";
                width: 44px;
                height: 44px;
                background-color: $secondary-color;
                border: 1px solid #e9e9e9;
                border-radius: 50%;
                position: absolute;
                bottom: -22px;
                right: 22px;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../images/ic_business_24px.svg);
            }
        }

        .media-body {
            margin: 30px 0;
        }
    }
}
.profile-description-seo .section-title
{
    border-bottom: 1px solid rgba(0,0,0,.1);    
    padding-bottom: 10px;
}
.abt-sec {
    .title-txt {
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        margin: 0 0 15px 0;
        border-bottom: 1px solid rgba(0,0,0,.1);
        padding-bottom: 10px;
    }

    .read-more {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-decoration: underline;
        color: $secondary-color;
        padding: 0;
        margin: 0 0 30px 0;
    }
}

.provider-list {
    background: transparent linear-gradient(0deg, #ffffff00 0%, #f9f9f9 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #e9e9e9;
    border-radius: 5px 5px 0px 0px;
    margin: 45px 0 0 0;

    hr {
        margin: 0;
    }

    .title-txt {
        font-weight: 600;
        font-size: 18px;
        line-height: 19px;
        color: $title-color;
        padding: 30px;
    }

    .list-sec-seo {
        padding: 15px 30px;
    }

    .dr-box {
        margin: 15px 0;

        .media-img {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            overflow: hidden;
        }

        .dr-name {
            font-weight: 700;
            color: #4a494a;
        }

        .dr-desg {
            font-size: 14px;
            color: #999;
        }

        .dr-rating {
            font-size: 12px;

            .ratting-star {
                display: inline-block;

                img {
                    height: 10px;
                    width: auto;
                }
            }
        }
    }
}

.insurance-btn {
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    background-color: #fff;
    padding: 15px 30px;
    position: relative;
    cursor: pointer;

    // &::after {
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     @include overlay();
    //     background-image: url(../images/ic_chevron_right_24px.svg);
    //     background-repeat: no-repeat;
    //     background-position: calc(100% - 30px) center;
    // }

    .media-body {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
    }
}

.map-sec-client {
    box-shadow: 0px 3px 10px #0000000d;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    overflow: hidden;

    iframe {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .profile-sec-seo .practician-name .media-img {
        height: 150px;
        width: 150px;
        margin-right: 30px !important;
    }
    .profile-sec-seo .practice_at + .btn-custom {
        position: absolute;
        top: auto;
        right: 30px;
        width: auto !important;
        padding: 5px 30px;
        font-size: 14px;
        bottom: 40px;
    }
    .profile-sec-seo .practician-name.media {
        display: flex;
        width: 100%;

        .trusted-txt {
            margin-bottom: 7px;
        }
    }

    .profile-sec-seo .practician-name .media-body {
        margin: 15px 0;
    }

    .practician-name .p-name {
        font-size: 30px;
        line-height: 40px;
    }

    .practician-name .ratting-value {
        margin-top: 7px !important;
    }

    .abt-sec {
        margin-top: 45px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .profile-sec-seo .practician-name .media-img {
        width: 80px;
        height: 80px;
        padding: 2px;
        top: -40px;
        margin-right: auto !important;
        margin-left: auto;
    }

    .profile-sec-seo .practician-name .media-img::after {
        height: 30px;
        width: 30px;
        bottom: -14px;
        right: 14px;
        background-size: auto 12px;
    }

    .profile-sec-seo .practician-name .media-body {
        margin: 5px 0 0 0;
    }
    .profile-sec-seo .btn-custom {
        font-size: 14px;
        padding: 5px 15px;
        img {
            width: 16px;
        }
    }
    .insurance-btn {
        padding: 15px 45px 15px 15px;
        margin: -30px 0 30px 0;
        .media-body {
            font-size: 16px;
        }
        .media-img {
            width: 24px;
        }
    }
}
