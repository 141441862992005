@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
body
{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
.container
{
    max-width: 400px;
}
.box-sec
{
    min-height: 100vh;
    a 
    {
        text-decoration: none;
        &:hover, &:focus, &:active, &:focus-within , &:focus-visible
        {
            box-shadow: none;
            outline: none; 
            text-decoration: none;           
        }
    }
    .btn-lg
    {
        height: 48px;
    }
}