.waitlist-sec
{
    .input-check , .input-radio
    {
        .form-check
        {           
            z-index: 0;
            input 
            {
                opacity: 0;
                z-index: 1;
                cursor: pointer;
            } 
            label 
            {                
                background-color: #e2e6ea;
                color: #6c757d ;
                transition: all 0.3s ease;
            }     
            input:checked + label 
            {
                background-color: rgb(13, 110, 253);
                color: #fff;
            }      
        }
    }
    .input-check
    {
        .form-check
        {
            width: 40px;
            height: 40px;
        }
        label 
        {
            line-height: 42px;
        }
    }
    .input-radio
    {
        .form-check
        {
            width: calc((100% - 30px ) / 3);
            margin: 0 15px 15px 0;
            &:nth-child(3n)
            {
                margin-right: 0;
            }
        }
        label, input 
        {
            border-radius: 80px;
        }
    }
    .day-selection
    {
        .input-selectors
        {
            padding-bottom: 15px;
        }
    }
}