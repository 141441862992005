.p-details {
    .input-selectors {}

    .input-check {
        .form-check {
            width: 40px;
            z-index: 0;
        }
        input 
        {
            opacity: 0;
            z-index: 1;
            cursor: pointer;
        } 
        label 
        {    
            color: #6c757d;
            line-height: 40px;
            height: 40px;
            margin-top: 10px;
        }     
        input:checked + label 
        {
            background-color: rgb(13, 110, 253);
            color: #fff;
        } 
        .small 
        {
            font-size: 12px!important;
        }
    }
    .input-radio
    {
        .form-check {
            width: calc((100% - 30px ) / 3);
            margin: 0 0 15px 0;
            z-index: 0;            
            margin-right: 15px;
            &:nth-child(3n)
            {
                margin-right: 0;
            }
        }
        input 
        {
            opacity: 0;
            z-index: 1;
            cursor: pointer;
        } 
        label 
        { 
            color: #212529;
            border: 1px solid #212529;            
            padding: 8px 0 6px 0;
            transition: all 0.3s ease;
        }     
        input:checked + label 
        {
            background-color: rgb(13, 110, 253);
            color: #fff;
            border-color: rgb(13, 110, 253);
        } 
    }
}