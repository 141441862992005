/* --- Common CSS Start --- */
$font-color: #4a494a;
$secondary-color: #e62b4a;
$aqua-color: #fff;
$primary-color: #2e88cd;
$title-color: #777f88;
$content-color: #95989a;

@mixin overlay() {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin: auto;
}

body {
    font-family: "Overpass", sans-serif;
    font-weight: 400;
    color: $font-color;
}

.container {
    max-width: 1170px;
}

p {
    font-size: 16px;
    line-height: 26px;
    color: $font-color;
    margin: 0 0 30px 0;
}

a {
    text-decoration: none;
    outline: 0;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        box-shadow: none !important;
    }
}

textarea {
    resize: none;
}

.like-h1 {
    font-size: 40px;
    line-height: 50px;
    color: $font-color;
    font-weight: 300;
    margin: 0 0 30px 0;

    strong {
        font-weight: 700 !important;
    }
}

ul {
    list-style: none;
    padding: 0;
}

section {
    background-color: #fff;
    // padding: 60px 0;
}

.btn-custom {
    padding: 12px 45px;
    font-size: 16px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    color: #fff;
    background-color: $secondary-color;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
    }
}

.section-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 15px 0;
    color: $font-color;
}

.hygiene_rating {
    color: #5cb85c;
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0 0 0;
    display: inline-block;
}

.img-box {
    .MuiAvatar-root {
        height: 100%;
        width: 100%;
        font-size: 24px;
        font-weight: 600;
    }
}

.media {
    .MuiAvatar-root {
        height: 100%;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
    }
}

.policyParent {
    padding: 60px 0;
    .policy_cnt {
        h2 {
            margin: 0 0 30px 0;
            font-size: 38px;
        }
        h3 {
            margin: 0 0 30px 0;
            font-size: 24px;
        }
        h4 {
            margin: 0 0 30px 0;
            font-size: 18px;
        }
        ul {
            padding: 0 0 0 15px;
            li {
                margin: 0 0 15px 0;
                list-style-type: disc;
            }
        }
    }
}
.frontloader {
    min-height: 90vh;
    img {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform: -webkit-translate(-50%, -50%);
        transform: -moz-translate(-50%, -50%);
        transform: -ms-translate(-50%, -50%);
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        max-width: 100px;
        z-index: 99999;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    }
}
.label-form {
    font-weight: 600;
}
/* --- Common CSS End --- */
/* --- css files start --- */
@import "_slick.scss";
@import "_elements.scss";
@import "_header.scss";
@import "_footer.scss";
@import "_navigation-menu.scss";
@import "_home.scss";
@import "_dentist-home.scss";
@import "_listing-page.scss";
@import "_client-detail.scss";
@import "_practice-profile-for-seo.scss";
@import "_provider-profile-for-seo.scss";
@import "_about.scss";
@import "_referral.scss";
@import "_wizard.scss";
@import "_popup.scss";
/* --- css files end --- */

/* --- Responsive Start --- */
@media only screen and (min-width: 1200px) {
    .w-80 {
        width: 80%;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .testimonials .right_coma {
        left: -35px;
        padding: 30px;
        height: 100px;
        width: 100px;
    }

    .dentist_dashboard .next_patient {
        right: -50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .master-header .navbar-brand {
        margin: 0 45px 0 0;
    }

    .master-header .navbar-brand img {
        max-width: 130px;
    }

    .master-header .nav-item .nav-link {
        font-size: 15px;
        line-height: 25px;
        padding: 20px 10px;
    }

    .master-header .nav-item .nav-link .tooltip .tooltip-inner {
        padding: 0 0.5rem;
    }

    .master-header .nav-item .btn-custom {
        margin: 15px 0 0 10px;
        padding: 4px 30px;
    }

    .feature-section .client-review-icon {
        width: 100px;
        height: 100px;
        right: 0;
        padding: 25px;
    }

    .feature-icon .feature-icon-section {
        padding: 40px 0px;
    }

    .feature-section h2 {
        width: 100%;
    }

    .feature-icon .feature-icon-section .f-title {
        max-width: 120px;
        width: 100%;
        margin: 0 auto;
    }

    .feature-icon .feature-icon-section .f-img {
        height: 60px;
    }

    .feature-icon .feature-icon-section .f-img img {
        height: 58px;
    }

    .testimonials .side_section .carousel_slide p {
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 45px 0;
    }

    .testimonials .side_section .carousel_slide h3 {
        font-size: 24px;
        line-height: normal;
    }

    .testimonials .side_section .carousel_slide h3 span {
        font-size: 14px;
        line-height: 32px;
    }

    .testimonials .right_coma {
        left: 0;
        top: -15px;
        width: 100px;
        height: 100px;
        padding: 25px;
        margin: 0;
    }

    .dentist_dashboard .dashboard .dash-img {
        max-width: 400px;
    }

    .dentist_dashboard .next_patient {
        right: 0;
        padding: 20px 25px;
    }

    .dentist_dashboard .next_patient .bell img {
        height: 32px;
        top: -12px;
    }

    .dentist_dashboard .next_patient .contact i {
        font-size: 14px;
    }

    .dentist_dashboard .next_patient .contact p {
        padding-left: 5px;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }

    .dentist_dashboard .next_patient .bell p {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }

    .dentist_dashboard .dashboard p {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .like-h1 {
        font-size: 34px;
        line-height: 44px;
    }

    .banner-section {
        height: 40vh;
    }

    .banner-section .txt-section {
        margin: 75px 0 0 0;
    }

    .banner-section .txt-section .address-section {
        width: 70%;
    }

    .banner-section .txt-section h1 {
        font-size: 36px;
        line-height: 36px;
        padding: 0;
    }

    .banner-section .txt-section .location-title {
        margin: 15px 0 0 0;
    }

    .feature-section {
        padding: 60px 0 15px 0;
    }

    .feature-section .feature-list li {
        padding: 0 0 30px 45px;
        width: 100%;
        background-position: 10px 4px;
    }

    .feature-section h2 {
        padding: 0 0 0 30px;
        width: 100%;
    }

    .feature-section .client-review-icon {
        height: 100px;
        width: 100px;
        right: -15px;
        padding: 30px;
    }

    .feature-icon .feature-icon-section .f-img {
        height: 60px;
    }

    .feature-icon .feature-icon-section .f-img img {
        height: 50px !important;
    }

    .feature-icon .feature-icon-section {
        padding: 30px 20px;
    }

    .provider-section .txt-box .que-txt {
        font-size: 16px;
    }

    .provider-section .txt-box .ans-txt {
        font-size: 14px;
    }

    .testimonials .side_section .carousel_slide {
        padding: 45px;
    }

    .testimonials .right_coma {
        height: 100px;
        width: 100px;
        padding: 30px;
        bottom: auto;
        top: -30px;
        left: 3px;
    }

    .testimonials .side_section .carousel_slide p {
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 45px 0;
    }

    .locaction-section .city-list {
        padding: 15px 15px 15px 40px;
    }

    .locaction-section .city-list::before {
        background-size: 15px;
        left: 15px;
    }

    .locaction-section .city-list .city-name {
        font-size: 14px;
        line-height: 24px;
    }

    .dentist_dashboard .dentist_pt ul.big_list li {
        width: 100%;
        background-size: 40px;
        padding: 0 0 0px 60px;
    }

    .dentist_dashboard .dashboard {
        margin: 30px 0 0 0;
        text-align: center;
    }

    .master-footer .social-title {
        font-size: 16px;
    }

    .master-footer .bottom-links {
        padding: 30px 0;
    }

    .master-footer .bottom-links li:first-child {
        margin: 0 0 7px 0;
    }

    .master-footer .bottom-links li:nth-child(2) {
        border-left: none;
    }

    .master-footer p {
        width: 90%;
        margin: 0;
    }

    .master-footer .top-sec {
        padding: 45px 0;
    }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    body.toggled {
        overflow: hidden;
    }

    .btn {
        font-size: 14px;
    }

    .banner-section {
        height: 75vh;
    }

    .banner-section .txt-section {
        margin: 75px 0 0 0;
    }

    .banner-section .txt-section h1 {
        font-size: 32px;
        line-height: 42px;
    }

    .banner-section .txt-section p {
        font-size: 16px;
        line-height: 24px;
    }

    .banner-section .txt-section .address-section {
        width: 100%;
    }

    .banner-section .txt-section .address-section .location-input {
        max-width: 100%;
        width: 100%;
    }

    .banner-section .txt-section .address-section .location-btn {
        right: -9px;
    }

    .like-h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .feature-section {
        padding: 60px 0 30px 0;
    }

    .feature-section h2 {
        padding: 0;
        width: 100%;
    }

    .feature-section h2::before {
        display: none;
    }

    .feature-section .feature-list li {
        padding: 0 0 30px 45px;
    }

    .feature-section .client-review-icon {
        right: 0;
        width: 90px;
        height: 90px;
        padding: 25px;
    }

    .feature-icon {
        padding: 30px 0 0 0;
    }

    .feature-icon .feature-icon-section {
        padding: 15px;
        margin: 0 0 30px 0;
    }

    .feature-icon .feature-icon-section .f-img {
        height: 60px;
    }

    .feature-icon .feature-icon-section .f-img img,
    .feature-icon .feature-icon-section .f-img .time,
    .feature-icon .feature-icon-section .f-img .user-card {
        height: 50px;
    }

    .feature-icon .feature-icon-section .f-title {
        font-size: 12px;
    }

    .provider-section {
        padding: 30px 0;
    }

    .provider-section .provider-box {
        margin: 15px 0 !important;
    }

    .provider-section .txt-box {
        margin: 0;
    }

    .testimonials .side_section {
        margin: 0 0 60px 0 !important;
    }

    .testimonials .right_coma {
        display: none;
    }

    .testimonials .side_section .carousel_slide {
        padding: 30px;
    }

    .testimonials .side_section .carousel_slide .bg_coma {
        bottom: 10px;
    }

    .testimonials .side_section .carousel_slide .bg_coma img {
        height: 48px;
    }

    .testimonials .slick-dots {
        bottom: -90px;
    }

    .locaction-section .city-list {
        font-size: 14px;
        line-height: 20px;
        padding: 15px 10px 15px 30px;
        margin: 7.5px 0;
    }

    .locaction-section .btn-custom {
        width: 100%;
        font-size: 14px;
        max-width: 290px;
    }

    .locaction-section .city-list::before {
        left: 10px;
        background-size: 10px;
    }

    .master-footer .bottom-links li:nth-child(2) {
        border-left: none;
    }

    .master-footer .link-list {
        margin: 0 0 30px 0;
    }

    .testimonials .side_section .carousel_slide p {
        font-size: 18px;
        line-height: 28px;
    }

    .master-footer .link-list li a {
        font-size: 15px;
        line-height: 30px;
    }

    .testimonials .bg-img {
        background-color: #f2f9ff;
        border: none;
    }

    .dentist_dashboard .bg_design {
        width: 100%;
        left: 0;
    }

    .dentist_dashboard .bg_design img {
        width: 100%;
        height: auto;
    }

    .dentist_dashboard .dentist_pt p {
        font-size: 20px;
        line-height: 30px;
    }

    .dentist_dashboard .dentist_pt ul.big_list li {
    }

    .dentist_dashboard .dashboard {
        margin: 25px 0 0 0;
        text-align: center;
    }

    .dentist_dashboard .dashboard p {
        margin: 0 0 15px 0;
    }

    .dentist_dashboard .dashboard .btn {
        font-size: 14px;
        padding: 12px 15px;
        width: 100%;
        max-width: 290px;
    }

    .master-footer p {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
    }

    .master-footer .top-sec {
        padding-bottom: 15px;
    }

    .master-footer .bottom-links {
        padding: 30px 0 0 0;
    }

    .master-footer .bottom-links li:first-child {
        padding-bottom: 10px;
    }

    .app-cta {
        text-align: center;

        .app-btn {
            display: inline-block;
            height: 48px;
            width: auto;
            margin: 0 5px 40px 5px;
        }
    }

    .list-page {
        .map-sec {
            display: none;
        }
    }

    .provider-section {
        .provider-name {
            img {
                max-width: 100%;
            }
        }
    }
}

/* --- Responsive menu start --- */
/* --- Responsive menu end --- */
@media only screen and (min-width: 360px) and (max-width: 440px) {
    .feature-icon .feature-icon-section .f-title {
        max-width: 120px;
        width: 100%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
    .feature-icon .feature-icon-section .f-title {
        font-size: 11px;
        padding: 0;
    }
}

/* --- Responsive End --- */


.cursor-pointer {
  cursor: pointer;
}

.success-popup {
  width: 100%;
  overflow-y: hidden;
}