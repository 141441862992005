$font-color: #4a494a;
$secondary-color: #e62b4a;
$aqua-color: #fff;
$primary-color: #2e88cd;
$title-color: #777f88;
$content-color: #95989a;
body {
    font-family: "Overpass", sans-serif;
    font-weight: 400;
    color: $font-color;
}
.container {
    max-width: 1170px;
}
p {
    font-size: 16px;
    line-height: 26px;
    color: $font-color;
    margin: 0 0 30px 0;
}
a {
    text-decoration: none;
    outline: 0;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        box-shadow: none !important;
    }
}
textarea {
    resize: none;
}
.like-h1 {
    font-size: 40px;
    line-height: 50px;
    color: $font-color;
    font-weight: 300;
    margin: 0 0 30px 0;
    strong {
        font-weight: 700 !important;
    }
}
ul {
    list-style: none;
    padding: 0;
}
section {
    background-color: #fff;
    // padding: 60px 0;
}
.btn-custom {
    padding: 12px 45px;
    font-size: 16px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    color: #fff;
    background-color: $secondary-color;
    &:hover,
    &:focus,
    &:active {
        color: #fff;
    }
}
.section-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 15px 0;
    color: $font-color;
}
/* --- Banner Section Start --- */
.clinic-search-sec {
    background-color: #fff;
    z-index: 0;
    position: relative;
    padding: 0 0;
    height: 100vh;
    .subtitle {
        color: #2e88cd;
    }
    .img-section {
        margin: 90px 0 0 0;
    }
    .txt-section {
        margin-top: 70px;
        h1 {
            font-size: 48px;
            line-height: 58px;
            color: #333333;
            font-weight: 300;
            strong {
                font-weight: 700 !important;
            }
        }
        p {
            color: #333333;
            font-size: 22px;
            line-height: 30px;
        }

        .location-title {
            color: #333333;
            line-height: normal;
            font-size: 14px;
            display: block;
            margin: 15px 0;
            &:hover,
            &:focus {
                box-shadow: none;
            }
            img {
                width: 16px;
                display: inline-block;
                margin: -2px 5px 0 0;
            }
        }
        .address-section {
            position: relative;
            background-color: #fff;
            border: 1px solid #95989a;
            height: 64px;
            z-index: 0;
            width: 640px;
            margin: auto;
            border-radius: 60px;
            padding: 15px;
            input {
                height: 100%;
                border: none !important;
                border-right: 1px solid #707070 !important;
                width: 190px;
                padding: 4px 15px 0 15px;
                color: #4a494a;
                &:focus,
                &:focus-within {
                    border-left: none !important;
                    border-top: none !important;
                    border-bottom: none !important;
                }
            }
            ::-webkit-input-placeholder {
                /* Edge */
                color: #4a494a;
                opacity: 1;
            }

            :-ms-input-placeholder {
                /* Internet Explorer */
                color: #4a494a;
                opacity: 1;
            }

            ::placeholder {
                color: #4a494a;
                opacity: 1;
            }
            .select-date {
                padding-left: 40px;
                background-image: url(../images/select-date-icon.svg);
                background-position: 15px center;
                background-repeat: no-repeat;
                .MuiInput-underline::before,
                .MuiInput-underline::after {
                    display: none;
                }
                input {
                    border: none !important;
                    color: #333;
                    padding-left: 0;
                    padding-top: 7px;
                }
                .MuiButtonBase-root.MuiIconButton-root {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: transparent;
                        outline: none;
                    }
                    .MuiIconButton-label,
                    .MuiTouchRipple-root {
                        display: none;
                    }
                }
            }
            .select-loc {
                padding-left: 30px;
                background-image: url(../images/select-location-icon.svg);
                background-position: 15px center;
                background-repeat: no-repeat;
                width: 220px;
                border-right: 1px solid #707070;
                border-radius: 0;
            }
            .select-spec {
                padding-right: 40px;
                background-image: url(../images/specialistes-btn.svg);
                background-position: calc(100% - 15px) center;
                background-repeat: no-repeat;
                border: none;
                border-right: 1px solid #707070;
                height: 32px;
                border-radius: 0;
                max-width: 200px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                &:focus {
                    box-shadow: none !important;
                }
            }
            .location-input {
                width: 95%;
                border-radius: 10px;
                padding: 16px 70px 16px 16px;
                border-color: transparent;
                color: $font-color;

                &::-webkit-input-placeholder {
                    /* Edge */
                    color: $font-color;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer */
                    color: $font-color;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &::placeholder {
                    color: $font-color;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .location-btn {
                width: 50px;
                height: 50px;
                border: none;
                border-radius: 50%;
                background-color: $secondary-color;
                display: block;
                text-align: center;
                line-height: 50px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 7px;
                margin: auto 0;
            }
        }
    }
}
.search-popup {
    .modal-dialog {
        margin: 0;
        padding: 15px 30px;
    }
    .modal-content {
        border: none;
    }
    .modal-header h5 {
        font-size: 22px;
        font-weight: 600;
    }
    label {
        font-weight: 600;
    }
    select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.169' height='3.943' viewBox='0 0 8.169 3.943'%3E%3Cpath id='Path_4549' data-name='Path 4549' d='M3106.946,2498h8.169l-4.273,3.943Z' transform='translate(-3106.946 -2498)' fill='%23373a3c'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) center;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 25px;
    }
    .form-check-input {
        width: 1em;
        height: 1em;
        margin-top: 0.25em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-top-color: rgba(0, 0, 0, 0.25);
        border-right-color: rgba(0, 0, 0, 0.25);
        border-bottom-color: rgba(0, 0, 0, 0.25);
        border-left-color: rgba(0, 0, 0, 0.25);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
    .form-check-input:checked[type="radio"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
    }
    .form-check-input:checked[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
    .form-check-input:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
    .form-check-input[type="radio"] {
        border-radius: 50%;
    }
    .form-check .form-check-input {
        float: left;
        margin-top: 0;
    }
    .form-check-input[type="checkbox"] {
        border-radius: 0.25em;
    }
}
.selectPaymentModel {
    .modal-dialog {
        max-width: 540px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        transform: translateY(-50%) !important;
        top: 45%;
    }
    .modal-content {
        border-radius: 0;
        border: none;
    }

    .btn-custom {
        padding: 8px 30px 4px 30px !important;
    }

    input,
    select,
    texarea,
    button {
        &:focus {
            outline: none !important;
        }
    }
    .search-popup {
        padding: 15px 30px;
    }
}
.is-invalid {
    color: #dc3545 !important;
    font-size: 14px;
}
.btn-close {
    background-image: url("data:image/svg+xml,%3Csvg id='Capa_1' enable-background='new 0 0 386.667 386.667' height='512' viewBox='0 0 386.667 386.667' width='512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    position: absolute;
    top: 7px;
    right: 7px;
}
@media only screen and (max-width: 767px) {
    .clinic-search-sec {
        .subtitle {
            display: block !important;
            .mr-2 {
                margin: 0 !important;
            }
        }
        .txt-section {
            h1 {
                font-size: 32px;
                line-height: 44px;
                max-width: 330px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
            p 
            {
                font-size: 18px;
            }
            .address-section {
                display: block !important;
                height: auto;
                width: 100%;
                border-radius: 10px;
                border:1px solid rgba(0,0,0,0.1);
                .select-spec,
                .select-loc,
                .select-date 
                {
                    width: 100%;
                    max-width: 100%;
                    border-right: 0!important;
                    border-bottom: 1px solid  rgba(0,0,0,0.1)!important;
                    margin-bottom: 15px;
                    padding: 5px 15px 5px 25px;
                    background-position: left center;
                }
                .location-btn {
                    position: static;
                    width: 100%;
                    border-radius: 0;
                    margin-top: 15px;
                }
            }
        }
    }
    .selectPaymentModel .modal-dialog
    {
        max-width: 360px;
        margin: auto;
    }
}
