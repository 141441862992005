.profile-sec-seo.for_provider {
    .btn-custom {
        margin: 0;
    }
}

// .seo-page-dr .profile-sec-seo.for_provider .practician-name .media-img::after {
// 	background-image: url(../images/doc_icon_w.svg);
// }

.dcqa {
    margin: 0 0 30px 0;
    .dcq {
        font-weight: 500;
        margin: 0 0 15px 0;
    }
}

.fdlang {
    margin: 30px 0 30px 0;
    background-color: #ffffff;
    box-shadow: 0px 3px 10px #0000000d;
    padding: 20px 30px;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    .badge {
        margin: 0 10px 0 0;
    }
}
.practice_at {
    .pfon {
        font-weight: 700;
    }
}
.practice_at {
    margin: 30px 0 20px 0;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .seo-page-dr {
        .provider-list .list-sec-seo
        {
            padding: 15px;
        }
        .provider-list .dr-box .media-img
        {
            width: 40px;
            height: 40px;
        }
        .section-title
        {
            font-size: 20px;            
        }
        .abt-sec
        {
            margin-top: 30px;
            .title-txt
            {
                font-size: 20px;
            }
        }
        .open-hours .title-sec, .provider-list .title-txt {
            font-size: 16px;
            padding: 15px;
        }
        .timeline-sec {
            min-height: 240px;
        }
        .open-hours .desc-sec {
            margin-bottom: 30px;
            ul {
                margin-bottom: 0;
                li {
                    font-size: 14px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .services-offered .all-services .all-service-list li {
            font-size: 16px;
        }
    }
}
